import React, { useState } from 'react';
import { Icon } from '../Icon/Icon';


import styles from './IconBox.module.scss';

export const IconBox = ({
    iconName,
    providerName,
    onIconClick,
}) => {
    const [size, setSize] = useState(null);

    return (
        <div className={styles.iconWrapper}>
            <Icon
                className={styles.icon}
                path={providerName}
                name={iconName}
                onClick={onIconClick}
                setSize={setSize}
            />

            <div className={styles.iconName}>
                <span className={styles.iconProvider}>{providerName}/</span>
                {iconName}
            </div>

            {size && <div className={styles.iconSize}>{size.toLocaleString()} b</div>}
        </div>
    );
};