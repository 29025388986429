export default [
    'arrows_anticlockwise',
    'arrows_anticlockwise_dashed',
    'arrows_button_down',
    'arrows_button_off',
    'arrows_button_on',
    'arrows_button_up',
    'arrows_check',
    'arrows_circle_check',
    'arrows_circle_down',
    'arrows_circle_downleft',
    'arrows_circle_downright',
    'arrows_circle_left',
    'arrows_circle_minus',
    'arrows_circle_plus',
    'arrows_circle_remove',
    'arrows_circle_right',
    'arrows_circle_up',
    'arrows_circle_upleft',
    'arrows_circle_upright',
    'arrows_clockwise',
    'arrows_clockwise_dashed',
    'arrows_compress',
    'arrows_deny',
    'arrows_diagonal',
    'arrows_diagonal2',
    'arrows_down',
    'arrows_down_double-34',
    'arrows_downleft',
    'arrows_downright',
    'arrows_drag_down',
    'arrows_drag_down_dashed',
    'arrows_drag_horiz',
    'arrows_drag_left',
    'arrows_drag_left_dashed',
    'arrows_drag_right',
    'arrows_drag_right_dashed',
    'arrows_drag_up',
    'arrows_drag_up_dashed',
    'arrows_drag_vert',
    'arrows_exclamation',
    'arrows_expand',
    'arrows_expand_diagonal1',
    'arrows_expand_horizontal1',
    'arrows_expand_vertical1',
    'arrows_fit_horizontal',
    'arrows_fit_vertical',
    'arrows_glide',
    'arrows_glide_horizontal',
    'arrows_glide_vertical',
    'arrows_hamburger 2',
    'arrows_hamburger1',
    'arrows_horizontal',
    'arrows_info',
    'arrows_keyboard_alt',
    'arrows_keyboard_cmd-29',
    'arrows_keyboard_delete',
    'arrows_keyboard_down-28',
    'arrows_keyboard_left',
    'arrows_keyboard_return',
    'arrows_keyboard_right',
    'arrows_keyboard_shift',
    'arrows_keyboard_tab',
    'arrows_keyboard_up',
    'arrows_left',
    'arrows_left_double-32',
    'arrows_minus',
    'arrows_move',
    'arrows_move2',
    'arrows_move_bottom',
    'arrows_move_left',
    'arrows_move_right',
    'arrows_move_top',
    'arrows_plus',
    'arrows_question',
    'arrows_remove',
    'arrows_right',
    'arrows_right_double-31',
    'arrows_rotate',
    'arrows_rotate_anti',
    'arrows_rotate_anti_dashed',
    'arrows_rotate_dashed',
    'arrows_shrink',
    'arrows_shrink_diagonal1',
    'arrows_shrink_diagonal2',
    'arrows_shrink_horizonal2',
    'arrows_shrink_horizontal1',
    'arrows_shrink_vertical1',
    'arrows_shrink_vertical2',
    'arrows_sign_down',
    'arrows_sign_left',
    'arrows_sign_right',
    'arrows_sign_up',
    'arrows_slide_down1',
    'arrows_slide_down2',
    'arrows_slide_left1',
    'arrows_slide_left2',
    'arrows_slide_right1',
    'arrows_slide_right2',
    'arrows_slide_up1',
    'arrows_slide_up2',
    'arrows_slim_down',
    'arrows_slim_down_dashed',
    'arrows_slim_left',
    'arrows_slim_left_dashed',
    'arrows_slim_right',
    'arrows_slim_right_dashed',
    'arrows_slim_up',
    'arrows_slim_up_dashed',
    'arrows_square_check',
    'arrows_square_down',
    'arrows_square_downleft',
    'arrows_square_downright',
    'arrows_square_left',
    'arrows_square_minus',
    'arrows_square_plus',
    'arrows_square_remove',
    'arrows_square_right',
    'arrows_square_up',
    'arrows_square_upleft',
    'arrows_square_upright',
    'arrows_squares',
    'arrows_stretch_diagonal1',
    'arrows_stretch_diagonal2',
    'arrows_stretch_diagonal3',
    'arrows_stretch_diagonal4',
    'arrows_stretch_horizontal1',
    'arrows_stretch_horizontal2',
    'arrows_stretch_vertical1',
    'arrows_stretch_vertical2',
    'arrows_switch_horizontal',
    'arrows_switch_vertical',
    'arrows_up',
    'arrows_up_double-33',
    'arrows_upleft',
    'arrows_upright',
    'arrows_vertical',
    'basic_accelerator',
    'basic_alarm',
    'basic_anchor',
    'basic_anticlockwise',
    'basic_archive',
    'basic_archive_full',
    'basic_ban',
    'basic_battery_charge',
    'basic_battery_empty',
    'basic_battery_full',
    'basic_battery_half',
    'basic_bolt',
    'basic_book',
    'basic_book_pen',
    'basic_book_pencil',
    'basic_bookmark',
    'basic_calculator',
    'basic_calendar',
    'basic_cards_diamonds',
    'basic_cards_hearts',
    'basic_case',
    'basic_chronometer',
    'basic_clessidre',
    'basic_clock',
    'basic_clockwise',
    'basic_cloud',
    'basic_clubs',
    'basic_compass',
    'basic_cup',
    'basic_diamonds',
    'basic_display',
    'basic_download',
    'basic_elaboration_bookmark_checck',
    'basic_elaboration_bookmark_minus',
    'basic_elaboration_bookmark_plus',
    'basic_elaboration_bookmark_remove',
    'basic_elaboration_briefcase_check',
    'basic_elaboration_briefcase_download',
    'basic_elaboration_briefcase_flagged',
    'basic_elaboration_briefcase_minus',
    'basic_elaboration_briefcase_plus',
    'basic_elaboration_briefcase_refresh',
    'basic_elaboration_briefcase_remove',
    'basic_elaboration_briefcase_search',
    'basic_elaboration_briefcase_star',
    'basic_elaboration_briefcase_upload',
    'basic_elaboration_browser_check',
    'basic_elaboration_browser_download',
    'basic_elaboration_browser_minus',
    'basic_elaboration_browser_plus',
    'basic_elaboration_browser_refresh',
    'basic_elaboration_browser_remove',
    'basic_elaboration_browser_search',
    'basic_elaboration_browser_star',
    'basic_elaboration_browser_upload',
    'basic_elaboration_calendar_check',
    'basic_elaboration_calendar_cloud',
    'basic_elaboration_calendar_download',
    'basic_elaboration_calendar_empty',
    'basic_elaboration_calendar_flagged',
    'basic_elaboration_calendar_heart',
    'basic_elaboration_calendar_minus',
    'basic_elaboration_calendar_next',
    'basic_elaboration_calendar_noaccess',
    'basic_elaboration_calendar_pencil',
    'basic_elaboration_calendar_plus',
    'basic_elaboration_calendar_previous',
    'basic_elaboration_calendar_refresh',
    'basic_elaboration_calendar_remove',
    'basic_elaboration_calendar_search',
    'basic_elaboration_calendar_star',
    'basic_elaboration_calendar_upload',
    'basic_elaboration_cloud_check',
    'basic_elaboration_cloud_download',
    'basic_elaboration_cloud_minus',
    'basic_elaboration_cloud_noaccess',
    'basic_elaboration_cloud_plus',
    'basic_elaboration_cloud_refresh',
    'basic_elaboration_cloud_remove',
    'basic_elaboration_cloud_search',
    'basic_elaboration_cloud_upload',
    'basic_elaboration_document_check',
    'basic_elaboration_document_cloud',
    'basic_elaboration_document_download',
    'basic_elaboration_document_flagged',
    'basic_elaboration_document_graph',
    'basic_elaboration_document_heart',
    'basic_elaboration_document_minus',
    'basic_elaboration_document_next',
    'basic_elaboration_document_noaccess',
    'basic_elaboration_document_note',
    'basic_elaboration_document_pencil',
    'basic_elaboration_document_picture',
    'basic_elaboration_document_plus',
    'basic_elaboration_document_previous',
    'basic_elaboration_document_refresh',
    'basic_elaboration_document_remove',
    'basic_elaboration_document_search',
    'basic_elaboration_document_star',
    'basic_elaboration_document_upload',
    'basic_elaboration_folder_check',
    'basic_elaboration_folder_cloud',
    'basic_elaboration_folder_document',
    'basic_elaboration_folder_download',
    'basic_elaboration_folder_flagged',
    'basic_elaboration_folder_graph',
    'basic_elaboration_folder_heart',
    'basic_elaboration_folder_minus',
    'basic_elaboration_folder_next',
    'basic_elaboration_folder_noaccess',
    'basic_elaboration_folder_note',
    'basic_elaboration_folder_pencil',
    'basic_elaboration_folder_picture',
    'basic_elaboration_folder_plus',
    'basic_elaboration_folder_previous',
    'basic_elaboration_folder_refresh',
    'basic_elaboration_folder_remove',
    'basic_elaboration_folder_search',
    'basic_elaboration_folder_star',
    'basic_elaboration_folder_upload',
    'basic_elaboration_mail_check',
    'basic_elaboration_mail_cloud',
    'basic_elaboration_mail_document',
    'basic_elaboration_mail_download',
    'basic_elaboration_mail_flagged',
    'basic_elaboration_mail_heart',
    'basic_elaboration_mail_next',
    'basic_elaboration_mail_noaccess',
    'basic_elaboration_mail_note',
    'basic_elaboration_mail_pencil',
    'basic_elaboration_mail_picture',
    'basic_elaboration_mail_previous',
    'basic_elaboration_mail_refresh',
    'basic_elaboration_mail_remove',
    'basic_elaboration_mail_search',
    'basic_elaboration_mail_star',
    'basic_elaboration_mail_upload',
    'basic_elaboration_message_check',
    'basic_elaboration_message_dots',
    'basic_elaboration_message_happy',
    'basic_elaboration_message_heart',
    'basic_elaboration_message_minus',
    'basic_elaboration_message_note',
    'basic_elaboration_message_plus',
    'basic_elaboration_message_refresh',
    'basic_elaboration_message_remove',
    'basic_elaboration_message_sad',
    'basic_elaboration_smartphone_cloud',
    'basic_elaboration_smartphone_heart',
    'basic_elaboration_smartphone_noaccess',
    'basic_elaboration_smartphone_note',
    'basic_elaboration_smartphone_pencil',
    'basic_elaboration_smartphone_picture',
    'basic_elaboration_smartphone_refresh',
    'basic_elaboration_smartphone_search',
    'basic_elaboration_tablet_cloud',
    'basic_elaboration_tablet_heart',
    'basic_elaboration_tablet_noaccess',
    'basic_elaboration_tablet_note',
    'basic_elaboration_tablet_pencil',
    'basic_elaboration_tablet_picture',
    'basic_elaboration_tablet_refresh',
    'basic_elaboration_tablet_search',
    'basic_elaboration_todolist_2',
    'basic_elaboration_todolist_check',
    'basic_elaboration_todolist_cloud',
    'basic_elaboration_todolist_download',
    'basic_elaboration_todolist_flagged',
    'basic_elaboration_todolist_minus',
    'basic_elaboration_todolist_noaccess',
    'basic_elaboration_todolist_pencil',
    'basic_elaboration_todolist_plus',
    'basic_elaboration_todolist_refresh',
    'basic_elaboration_todolist_remove',
    'basic_elaboration_todolist_search',
    'basic_elaboration_todolist_star',
    'basic_elaboration_todolist_upload',
    'basic_exclamation',
    'basic_eye',
    'basic_eye_closed',
    'basic_female',
    'basic_flag1',
    'basic_flag2',
    'basic_floppydisk',
    'basic_folder',
    'basic_folder_multiple',
    'basic_gear',
    'basic_geolocalize-01',
    'basic_geolocalize-05',
    'basic_globe',
    'basic_gunsight',
    'basic_hammer',
    'basic_headset',
    'basic_heart',
    'basic_heart_broken',
    'basic_helm',
    'basic_home',
    'basic_info',
    'basic_ipod',
    'basic_joypad',
    'basic_key',
    'basic_keyboard',
    'basic_laptop',
    'basic_life_buoy',
    'basic_lightbulb',
    'basic_link',
    'basic_lock',
    'basic_lock_open',
    'basic_magic_mouse',
    'basic_magnifier',
    'basic_magnifier_minus',
    'basic_magnifier_plus',
    'basic_mail',
    'basic_mail_multiple',
    'basic_mail_open',
    'basic_mail_open_text',
    'basic_male',
    'basic_map',
    'basic_message',
    'basic_message_multiple',
    'basic_message_txt',
    'basic_mixer2',
    'basic_mouse',
    'basic_notebook',
    'basic_notebook_pen',
    'basic_notebook_pencil',
    'basic_paperplane',
    'basic_pencil_ruler',
    'basic_pencil_ruler_pen ',
    'basic_photo',
    'basic_picture',
    'basic_picture_multiple',
    'basic_pin1',
    'basic_pin2',
    'basic_postcard',
    'basic_postcard_multiple',
    'basic_printer',
    'basic_question',
    'basic_rss',
    'basic_server',
    'basic_server2',
    'basic_server_cloud',
    'basic_server_download',
    'basic_server_upload',
    'basic_settings',
    'basic_share',
    'basic_sheet',
    'basic_sheet_multiple ',
    'basic_sheet_pen',
    'basic_sheet_pencil',
    'basic_sheet_txt ',
    'basic_signs',
    'basic_smartphone',
    'basic_spades',
    'basic_spread',
    'basic_spread_bookmark',
    'basic_spread_text',
    'basic_spread_text_bookmark',
    'basic_star',
    'basic_tablet',
    'basic_target',
    'basic_todo',
    'basic_todo_pen ',
    'basic_todo_pencil',
    'basic_todo_txt',
    'basic_todolist_pen',
    'basic_todolist_pencil',
    'basic_trashcan',
    'basic_trashcan_full',
    'basic_trashcan_refresh',
    'basic_trashcan_remove',
    'basic_upload',
    'basic_usb',
    'basic_video',
    'basic_watch',
    'basic_webpage',
    'basic_webpage_img_txt',
    'basic_webpage_multiple',
    'basic_webpage_txt',
    'basic_world',
    'ecommerce_bag',
    'ecommerce_bag_check',
    'ecommerce_bag_cloud',
    'ecommerce_bag_download',
    'ecommerce_bag_minus',
    'ecommerce_bag_plus',
    'ecommerce_bag_refresh',
    'ecommerce_bag_remove',
    'ecommerce_bag_search',
    'ecommerce_bag_upload',
    'ecommerce_banknote',
    'ecommerce_banknotes',
    'ecommerce_basket',
    'ecommerce_basket_check',
    'ecommerce_basket_cloud',
    'ecommerce_basket_download',
    'ecommerce_basket_minus',
    'ecommerce_basket_plus',
    'ecommerce_basket_refresh',
    'ecommerce_basket_remove',
    'ecommerce_basket_search',
    'ecommerce_basket_upload',
    'ecommerce_bath',
    'ecommerce_cart',
    'ecommerce_cart_check',
    'ecommerce_cart_cloud',
    'ecommerce_cart_content',
    'ecommerce_cart_download',
    'ecommerce_cart_minus',
    'ecommerce_cart_plus',
    'ecommerce_cart_refresh',
    'ecommerce_cart_remove',
    'ecommerce_cart_search',
    'ecommerce_cart_upload',
    'ecommerce_cent',
    'ecommerce_colon',
    'ecommerce_creditcard',
    'ecommerce_diamond',
    'ecommerce_dollar',
    'ecommerce_euro',
    'ecommerce_franc',
    'ecommerce_gift',
    'ecommerce_graph1',
    'ecommerce_graph2',
    'ecommerce_graph3',
    'ecommerce_graph_decrease',
    'ecommerce_graph_increase',
    'ecommerce_guarani',
    'ecommerce_kips',
    'ecommerce_lira',
    'ecommerce_megaphone',
    'ecommerce_money',
    'ecommerce_naira',
    'ecommerce_pesos',
    'ecommerce_pound',
    'ecommerce_receipt',
    'ecommerce_receipt_bath',
    'ecommerce_receipt_cent',
    'ecommerce_receipt_dollar',
    'ecommerce_receipt_euro',
    'ecommerce_receipt_franc',
    'ecommerce_receipt_guarani',
    'ecommerce_receipt_kips',
    'ecommerce_receipt_lira',
    'ecommerce_receipt_naira',
    'ecommerce_receipt_pesos',
    'ecommerce_receipt_pound',
    'ecommerce_receipt_rublo',
    'ecommerce_receipt_rupee',
    'ecommerce_receipt_tugrik',
    'ecommerce_receipt_won',
    'ecommerce_receipt_yen',
    'ecommerce_receipt_yen2',
    'ecommerce_recept_colon',
    'ecommerce_rublo',
    'ecommerce_rupee',
    'ecommerce_safe',
    'ecommerce_sale',
    'ecommerce_sales',
    'ecommerce_ticket',
    'ecommerce_tugriks',
    'ecommerce_wallet',
    'ecommerce_won',
    'ecommerce_yen',
    'ecommerce_yen2',
    'music_beginning_button',
    'music_bell',
    'music_cd',
    'music_diapason',
    'music_eject_button',
    'music_end_button',
    'music_fastforward_button',
    'music_headphones',
    'music_ipod',
    'music_loudspeaker',
    'music_microphone',
    'music_microphone_old',
    'music_mixer',
    'music_mute',
    'music_note_multiple',
    'music_note_single',
    'music_pause_button',
    'music_play_button',
    'music_playlist',
    'music_radio_ghettoblaster',
    'music_radio_portable',
    'music_record',
    'music_recordplayer',
    'music_repeat_button',
    'music_rewind_button',
    'music_shuffle_button',
    'music_stop_button',
    'music_tape',
    'music_volume_down',
    'music_volume_up',
    'software-horizontal_align_left',
    'software_add_vectorpoint',
    'software_box_oval',
    'software_box_polygon',
    'software_box_rectangle',
    'software_box_roundedrectangle',
    'software_character',
    'software_crop',
    'software_eyedropper',
    'software_font_allcaps',
    'software_font_baseline_shift',
    'software_font_horizontal_scale',
    'software_font_kerning',
    'software_font_leading',
    'software_font_size',
    'software_font_smallcapital',
    'software_font_smallcaps',
    'software_font_strikethrough',
    'software_font_tracking',
    'software_font_underline',
    'software_font_vertical_scale',
    'software_horizontal_align_center',
    'software_horizontal_align_right',
    'software_horizontal_distribute_center',
    'software_horizontal_distribute_left',
    'software_horizontal_distribute_right',
    'software_indent_firstline',
    'software_indent_left',
    'software_indent_right',
    'software_lasso',
    'software_layers1',
    'software_layers2',
    'software_layout-8boxes',
    'software_layout',
    'software_layout_2columns',
    'software_layout_3columns',
    'software_layout_4boxes',
    'software_layout_4columns',
    'software_layout_4lines',
    'software_layout_header',
    'software_layout_header_2columns',
    'software_layout_header_3columns',
    'software_layout_header_4boxes',
    'software_layout_header_4columns',
    'software_layout_header_complex',
    'software_layout_header_complex2',
    'software_layout_header_complex3',
    'software_layout_header_complex4',
    'software_layout_header_sideleft',
    'software_layout_header_sideright',
    'software_layout_sidebar_left',
    'software_layout_sidebar_right',
    'software_magnete',
    'software_pages',
    'software_paintbrush',
    'software_paintbucket',
    'software_paintroller',
    'software_paragraph',
    'software_paragraph_align_left',
    'software_paragraph_align_right',
    'software_paragraph_center',
    'software_paragraph_justify_all',
    'software_paragraph_justify_center',
    'software_paragraph_justify_left',
    'software_paragraph_justify_right',
    'software_paragraph_space_after',
    'software_paragraph_space_before',
    'software_pathfinder_exclude',
    'software_pathfinder_intersect',
    'software_pathfinder_subtract',
    'software_pathfinder_unite',
    'software_pen',
    'software_pen_add',
    'software_pen_remove',
    'software_pencil',
    'software_polygonallasso',
    'software_reflect_horizontal',
    'software_reflect_vertical',
    'software_remove_vectorpoint',
    'software_scale_expand',
    'software_scale_reduce',
    'software_selection_oval',
    'software_selection_polygon',
    'software_selection_rectangle',
    'software_selection_roundedrectangle',
    'software_shape_oval',
    'software_shape_polygon',
    'software_shape_rectangle',
    'software_shape_roundedrectangle',
    'software_slice',
    'software_transform_bezier',
    'software_vector_box',
    'software_vector_composite',
    'software_vector_line',
    'software_vertical_align_bottom',
    'software_vertical_align_center',
    'software_vertical_align_top',
    'software_vertical_distribute_bottom',
    'software_vertical_distribute_center',
    'software_vertical_distribute_top',
    'weather_aquarius',
    'weather_aries',
    'weather_cancer',
    'weather_capricorn',
    'weather_cloud',
    'weather_cloud_drop',
    'weather_cloud_lightning',
    'weather_cloud_snowflake',
    'weather_downpour_fullmoon',
    'weather_downpour_halfmoon',
    'weather_downpour_sun',
    'weather_drop',
    'weather_first_quarter ',
    'weather_fog',
    'weather_fog_fullmoon',
    'weather_fog_halfmoon',
    'weather_fog_sun',
    'weather_fullmoon',
    'weather_gemini',
    'weather_hail',
    'weather_hail_fullmoon',
    'weather_hail_halfmoon',
    'weather_hail_sun',
    'weather_last_quarter',
    'weather_leo',
    'weather_libra',
    'weather_lightning',
    'weather_mistyrain',
    'weather_mistyrain_fullmoon',
    'weather_mistyrain_halfmoon',
    'weather_mistyrain_sun',
    'weather_moon',
    'weather_moondown_full',
    'weather_moondown_half',
    'weather_moonset_full',
    'weather_moonset_half',
    'weather_move2',
    'weather_newmoon',
    'weather_pisces',
    'weather_rain',
    'weather_rain_fullmoon',
    'weather_rain_halfmoon',
    'weather_rain_sun',
    'weather_sagittarius',
    'weather_scorpio',
    'weather_snow',
    'weather_snow_fullmoon',
    'weather_snow_halfmoon',
    'weather_snow_sun',
    'weather_snowflake',
    'weather_star',
    'weather_storm-11',
    'weather_storm-32',
    'weather_storm_fullmoon',
    'weather_storm_halfmoon',
    'weather_storm_sun',
    'weather_sun',
    'weather_sundown',
    'weather_sunset',
    'weather_taurus',
    'weather_tempest',
    'weather_tempest_fullmoon',
    'weather_tempest_halfmoon',
    'weather_tempest_sun',
    'weather_variable_fullmoon',
    'weather_variable_halfmoon',
    'weather_variable_sun',
    'weather_virgo',
    'weather_waning_cresent',
    'weather_waning_gibbous',
    'weather_waxing_cresent',
    'weather_waxing_gibbous',
    'weather_wind',
    'weather_wind_E',
    'weather_wind_N',
    'weather_wind_NE',
    'weather_wind_NW',
    'weather_wind_S',
    'weather_wind_SE',
    'weather_wind_SW',
    'weather_wind_W',
    'weather_wind_fullmoon',
    'weather_wind_halfmoon',
    'weather_wind_sun',
    'weather_windgust',
];