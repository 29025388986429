import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Icon } from '../Icon/Icon';
import iconsIcon54 from '../iconsList/icon54';
import iconsMdi from '../iconsList/mdiIcons';
import iconsFeather from '../iconsList/feather';
import iconsLinea from '../iconsList/linea';
import iconsSimple from '../iconsList/simpleIcons';
import iconsUntitled from '../iconsList/untitled';

import styles from './Layout.module.scss';
import { IconBox } from '../IconBox/IconBox';
import SVG from 'react-inlinesvg';

const DEFAULT_LIMIT = 7*8;
const initialSearchTerm = window.location.hash.replace('#', '');
const iconProviders = [
    { name: 'icon54', icons: iconsIcon54 },
    { name: 'mdi', icons: iconsMdi },
    { name: 'feather', icons: iconsFeather },
    { name: 'linea', icons: iconsLinea },
    { name: 'simple', icons: iconsSimple },
    { name: 'untitled', icons: iconsUntitled },
];
let msgTimeout;

const filterIcons = ({ searchTerm, provider }) => {
    const { name: providerName, icons = [] } = provider;
    return icons.filter(iconName => {
        return iconName.toLowerCase().includes(searchTerm.toLowerCase());
    }).map(iconName => ({ iconName, providerName }));
};

const getIcons = ({ searchTerm }) => {
    let providerName;
    const parts = searchTerm.split('/');
    if (parts.length === 2) {
        providerName = parts[0];
        searchTerm = parts[1];
    }

    const providers = !!providerName
        ? iconProviders.filter(({ name }) => name === providerName)
        : iconProviders;

    let result = [];
    providers.forEach(provider => {
       result = result.concat(
           filterIcons({ searchTerm, provider })
       )
    });
    return result;
};

export const Layout = () => {
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [isVisibleClipboardMsg, setIsVisibleClipboardMsg] = useState(false);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 200);

    const onIconClick = async ({ path, name }) => {
        console.log({ path, name });

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('remote-receiver')) {
            window.opener.postMessage({
                action: 'onIconClick',
                path: `${path}/${name}`
            }, '*');
            window.close();
            return;
        }

        await navigator.clipboard.writeText(`${path}/${name}`);
        setIsVisibleClipboardMsg(true);
        clearTimeout(msgTimeout);
        msgTimeout = setTimeout(() => setIsVisibleClipboardMsg(false), 400);
    };

    const onInputChange = ({ target }) => {
        setSearchTerm(target.value);
        window.location.hash = target.value;
        setLimit(DEFAULT_LIMIT);
    };

    const icons = getIcons({ searchTerm: debouncedSearchTerm });

    return (
        <div className={styles.wrapper}>
            {isVisibleClipboardMsg &&
            <div className={styles.clipboardMsg}>
                Copied to clipboard
            </div>
            }

            <div className={styles.searchFormWrapper}>
                <div className={styles.searchInputWrapper}>

                    <SVG src={`/icon54/search.3.svg`}/>

                    <input
                        type="text"
                        onChange={onInputChange}
                        value={searchTerm}
                    />
                </div>

                <div className={styles.showingInfo}>
                    Showing {icons.length < limit ? icons.length : limit} of {icons.length.toLocaleString()}
                </div>
            </div>

            <div className={styles.iconsWrapper}>
                {icons.slice(0, limit).map(({ iconName, providerName }) => {
                    return (
                        <IconBox
                            key={`${iconName}-${providerName}`}
                            iconName={iconName}
                            providerName={providerName}
                            onIconClick={onIconClick}
                        />
                    )
                })}
                {icons.length > limit &&
                    <div
                        className={styles.showMoreTile}
                        onClick={() => setLimit(limit + DEFAULT_LIMIT)}
                    >
                        <Icon
                            path="feather"
                            name="more-horizontal"
                        />
                        Show more
                    </div>
                }
            </div>
        </div>
    )
};
