export default [
    '1password',
    '500px',
    'abbrobotstudio',
    'about-dot-me',
    'abstract',
    'academia',
    'accusoft',
    'acm',
    'actigraph',
    'activision',
    'addthis',
    'adguard',
    'adobe',
    'adobeacrobatreader',
    'adobeaftereffects',
    'adobeaudition',
    'adobecreativecloud',
    'adobedreamweaver',
    'adobeillustrator',
    'adobeindesign',
    'adobelightroomcc',
    'adobelightroomclassic',
    'adobephonegap',
    'adobephotoshop',
    'adobepremiere',
    'adobetypekit',
    'adobexd',
    'airbnb',
    'airplayaudio',
    'airplayvideo',
    'algolia',
    'alipay',
    'alliedmodders',
    'allocine',
    'amazon',
    'amazonalexa',
    'amazonaws',
    'amd',
    'americanexpress',
    'anaconda',
    'analogue',
    'anchor',
    'android',
    'angellist',
    'angular',
    'angularuniversal',
    'ansible',
    'apache',
    'apacheairflow',
    'apachecordova',
    'apacheflink',
    'apachekafka',
    'apachenetbeanside',
    'apacheopenoffice',
    'apacherocketmq',
    'apachespark',
    'apple',
    'applemusic',
    'applepay',
    'applepodcasts',
    'appveyor',
    'aral',
    'archiveofourown',
    'archlinux',
    'arduino',
    'artstation',
    'arxiv',
    'asana',
    'asciidoctor',
    'asciinema',
    'at-and-t',
    'atlassian',
    'atom',
    'audi',
    'audible',
    'audiomack',
    'aurelia',
    'auth0',
    'authy',
    'automatic',
    'autotask',
    'aventrix',
    'awesomewm',
    'azureartifacts',
    'azuredevops',
    'azurepipelines',
    'babel',
    'baidu',
    'bamboo',
    'bancontact',
    'bandcamp',
    'bandlab',
    'basecamp',
    'bathasu',
    'battle-dot-net',
    'beats',
    'beatsbydre',
    'behance',
    'bigcartel',
    'bing',
    'bit',
    'bitbucket',
    'bitcoin',
    'bitdefender',
    'bitly',
    'bitrise',
    'blackberry',
    'blender',
    'blogger',
    'bmcsoftware',
    'boeing',
    'boost',
    'bootstrap',
    'bower',
    'box',
    'brand-dot-ai',
    'brandfolder',
    'brave',
    'breaker',
    'broadcom',
    'buddy',
    'buffer',
    'buymeacoffee',
    'buzzfeed',
    'c',
    'cakephp',
    'campaignmonitor',
    'canva',
    'cashapp',
    'cassandra',
    'castorama',
    'castro',
    'celery',
    'centos',
    'cevo',
    'chase',
    'chef',
    'circle',
    'circleci',
    'cirrusci',
    'cisco',
    'civicrm',
    'clockify',
    'clojure',
    'cloudbees',
    'cloudflare',
    'cmake',
    'co-op',
    'codacy',
    'codecademy',
    'codechef',
    'codeclimate',
    'codecov',
    'codefactor',
    'codeforces',
    'codeigniter',
    'codepen',
    'coderwall',
    'codesandbox',
    'codeship',
    'codewars',
    'codio',
    'coffeescript',
    'coinbase',
    'commonworkflowlanguage',
    'composer',
    'compropago',
    'conda-forge',
    'conekta',
    'confluence',
    'convertio',
    'coronaengine',
    'coronarenderer',
    'coursera',
    'coveralls',
    'cpanel',
    'cplusplus',
    'creativecommons',
    'crehana',
    'crunchbase',
    'crunchyroll',
    'cryengine',
    'csharp',
    'css3',
    'csswizardry',
    'curl',
    'd3-dot-js',
    'dailymotion',
    'dart',
    'dashlane',
    'dassaultsystemes',
    'datacamp',
    'datadog',
    'dazn',
    'dblp',
    'debian',
    'deepin',
    'deezer',
    'delicious',
    'deliveroo',
    'dell',
    'deno',
    'dependabot',
    'designernews',
    'dev-dot-to',
    'deviantart',
    'devrant',
    'diaspora',
    'digg',
    'digitalocean',
    'directus',
    'discord',
    'discourse',
    'discover',
    'disqus',
    'disroot',
    'django',
    'dlna',
    'docker',
    'docusign',
    'dolby',
    'dot-net',
    'draugiem-dot-lv',
    'dribbble',
    'drone',
    'dropbox',
    'drupal',
    'dtube',
    'duckduckgo',
    'dunked',
    'duolingo',
    'dynatrace',
    'ea',
    'ebay',
    'eclipseide',
    'elastic',
    'elasticcloud',
    'elasticsearch',
    'elasticstack',
    'electron',
    'elementary',
    'eleventy',
    'ello',
    'elsevier',
    'ember-dot-js',
    'emlakjet',
    'empirekred',
    'envato',
    'epel',
    'epicgames',
    'epson',
    'esea',
    'eslint',
    'ethereum',
    'etsy',
    'eventbrite',
    'eventstore',
    'evernote',
    'everplaces',
    'evry',
    'exercism',
    'expertsexchange',
    'expo',
    'eyeem',
    'f-droid',
    'f-secure',
    'facebook',
    'faceit',
    'fandango',
    'fandom',
    'favro',
    'feathub',
    'fedora',
    'feedly',
    'fidoalliance',
    'figma',
    'figshare',
    'filezilla',
    'firebase',
    'fitbit',
    'fiverr',
    'flask',
    'flattr',
    'flickr',
    'flipboard',
    'floatplane',
    'flutter',
    'fnac',
    'formstack',
    'fossa',
    'fossilscm',
    'foursquare',
    'framer',
    'freebsd',
    'freecodecamp',
    'freelancer',
    'fujifilm',
    'fujitsu',
    'furaffinity',
    'furrynetwork',
    'garmin',
    'gatsby',
    'gauges',
    'genius',
    'gentoo',
    'geocaching',
    'gerrit',
    'ghost',
    'gimp',
    'git',
    'gitea',
    'github',
    'gitlab',
    'gitpod',
    'gitter',
    'glassdoor',
    'glitch',
    'gmail',
    'gnome',
    'gnu',
    'gnubash',
    'gnuemacs',
    'gnuicecat',
    'gnuprivacyguard',
    'gnusocial',
    'go',
    'godotengine',
    'gog-dot-com',
    'goldenline',
    'goodreads',
    'google',
    'googleads',
    'googleanalytics',
    'googlechrome',
    'googlecloud',
    'googledrive',
    'googlehangouts',
    'googlehangoutschat',
    'googlekeep',
    'googlepay',
    'googleplay',
    'googlepodcasts',
    'googlescholar',
    'googlesearchconsole',
    'gov-dot-uk',
    'gradle',
    'grafana',
    'graphcool',
    'graphql',
    'grav',
    'gravatar',
    'greenkeeper',
    'greensock',
    'groovy',
    'groupon',
    'gulp',
    'gumroad',
    'gumtree',
    'gutenberg',
    'habr',
    'hackaday',
    'hackerearth',
    'hackerone',
    'hackerrank',
    'hackhands',
    'hackster',
    'happycow',
    'hashnode',
    'haskell',
    'hatenabookmark',
    'haxe',
    'helm',
    'here',
    'heroku',
    'hexo',
    'highly',
    'hipchat',
    'hitachi',
    'hockeyapp',
    'homeassistant',
    'homify',
    'hootsuite',
    'houzz',
    'hp',
    'html5',
    'htmlacademy',
    'huawei',
    'hubspot',
    'hulu',
    'humblebundle',
    'hurriyetemlak',
    'hypothesis',
    'iata',
    'ibm',
    'icloud',
    'icomoon',
    'icon',
    'iconjar',
    'icq',
    'ideal',
    'ifixit',
    'ifood',
    'imdb',
    'imgur',
    'indeed',
    'influxdb',
    'inkscape',
    'instacart',
    'instagram',
    'instapaper',
    'intel',
    'intellijidea',
    'intercom',
    'internetarchive',
    'internetexplorer',
    'invision',
    'invoiceninja',
    'ionic',
    'ios',
    'ipfs',
    'issuu',
    'itch-dot-io',
    'itunes',
    'jabber',
    'java',
    'javascript',
    'jekyll',
    'jenkins',
    'jest',
    'jet',
    'jetbrains',
    'jinja',
    'jira',
    'joomla',
    'jquery',
    'jsdelivr',
    'jsfiddle',
    'json',
    'jupyter',
    'justgiving',
    'kaggle',
    'kaios',
    'kaspersky',
    'kentico',
    'keras',
    'keybase',
    'keycdn',
    'khanacademy',
    'kibana',
    'kickstarter',
    'kik',
    'kirby',
    'klout',
    'known',
    'ko-fi',
    'kodi',
    'koding',
    'kotlin',
    'krita',
    'kubernetes',
    'laravel',
    'laravelhorizon',
    'laravelnova',
    'last-dot-fm',
    'lastpass',
    'latex',
    'launchpad',
    'leetcode',
    'lenovo',
    'letsencrypt',
    'letterboxd',
    'lgtm',
    'liberapay',
    'librarything',
    'libreoffice',
    'line',
    'linewebtoon',
    'linkedin',
    'linode',
    'linux',
    'linuxfoundation',
    'linuxmint',
    'litecoin',
    'livejournal',
    'livestream',
    'llvm',
    'logstash',
    'loop',
    'lua',
    'lufthansa',
    'lumen',
    'lyft',
    'macys',
    'magento',
    'magisk',
    'mail-dot-ru',
    'mailchimp',
    'makerbot',
    'manageiq',
    'manjaro',
    'mapbox',
    'markdown',
    'marketo',
    'mastercard',
    'mastodon',
    'material-ui',
    'materialdesign',
    'mathworks',
    'matrix',
    'mattermost',
    'matternet',
    'mcafee',
    'mdnwebdocs',
    'mediafire',
    'mediatemple',
    'medium',
    'meetup',
    'mega',
    'mendeley',
    'mercedes',
    'messenger',
    'meteor',
    'micro-dot-blog',
    'microgenetics',
    'microsoft',
    'microsoftaccess',
    'microsoftazure',
    'microsoftedge',
    'microsoftexcel',
    'microsoftoffice',
    'microsoftonedrive',
    'microsoftonenote',
    'microsoftoutlook',
    'microsoftpowerpoint',
    'microsoftteams',
    'microsoftword',
    'microstrategy',
    'minds',
    'minetest',
    'minutemailer',
    'mix',
    'mixcloud',
    'mixer',
    'mojang',
    'monero',
    'mongodb',
    'monkeytie',
    'monogram',
    'monster',
    'monzo',
    'moo',
    'mozilla',
    'mozillafirefox',
    'musescore',
    'mxlinux',
    'myspace',
    'mysql',
    'nativescript',
    'ndr',
    'nec',
    'neo4j',
    'neovim',
    'netflix',
    'netlify',
    'newyorktimes',
    'next-dot-js',
    'nextcloud',
    'nextdoor',
    'nginx',
    'nim',
    'nintendo',
    'nintendo3ds',
    'nintendogamecube',
    'nintendoswitch',
    'node-dot-js',
    'node-red',
    'nodemon',
    'nokia',
    'notion',
    'notist',
    'npm',
    'nucleo',
    'nuget',
    'nutanix',
    'nuxt-dot-js',
    'nvidia',
    'obsstudio',
    'ocaml',
    'octave',
    'octopusdeploy',
    'oculus',
    'odnoklassniki',
    'openaccess',
    'openapiinitiative',
    'opencollective',
    'openid',
    'opensourceinitiative',
    'openssl',
    'openstreetmap',
    'opensuse',
    'openvpn',
    'opera',
    'opsgenie',
    'oracle',
    'orcid',
    'origin',
    'osmc',
    'overcast',
    'overleaf',
    'ovh',
    'pagekit',
    'palantir',
    'paloaltosoftware',
    'pandora',
    'pantheon',
    'parse-dot-ly',
    'pastebin',
    'patreon',
    'paypal',
    'peertube',
    'periscope',
    'photocrowd',
    'php',
    'pi-hole',
    'picarto-dot-tv',
    'pinboard',
    'pingdom',
    'pingup',
    'pinterest',
    'pivotaltracker',
    'pjsip',
    'plangrid',
    'player-dot-me',
    'playerfm',
    'playstation',
    'playstation3',
    'playstation4',
    'plesk',
    'plex',
    'pluralsight',
    'plurk',
    'pocket',
    'pocketcasts',
    'postgresql',
    'postman',
    'postwoman',
    'powershell',
    'prestashop',
    'prettier',
    'prismic',
    'probot',
    'processwire',
    'producthunt',
    'prometheus',
    'proto-dot-io',
    'protonmail',
    'proxmox',
    'publons',
    'purescript',
    'pypi',
    'python',
    'pytorch',
    'pyup',
    'qemu',
    'qgis',
    'qiita',
    'qualcomm',
    'quantcast',
    'quantopian',
    'quarkus',
    'quicktime',
    'quip',
    'quora',
    'qwiklabs',
    'qzone',
    'r',
    'rabbitmq',
    'radiopublic',
    'rails',
    'raspberrypi',
    'react',
    'reactos',
    'reactrouter',
    'readthedocs',
    'realm',
    'reason',
    'reasonstudios',
    'redbubble',
    'reddit',
    'redhat',
    'redis',
    'redux',
    'renren',
    'repl-dot-it',
    'researchgate',
    'reverbnation',
    'riot',
    'ripple',
    'riseup',
    'rollup-dot-js',
    'roots',
    'roundcube',
    'rss',
    'rstudio',
    'rtlzwei',
    'ruby',
    'rubygems',
    'runkeeper',
    'rust',
    'safari',
    'sahibinden',
    'salesforce',
    'saltstack',
    'samsung',
    'samsungpay',
    'sap',
    'sass',
    'sat-dot-1',
    'saucelabs',
    'scala',
    'scaleway',
    'scribd',
    'scrutinizerci',
    'seagate',
    'sega',
    'sellfy',
    'semaphoreci',
    'sencha',
    'sensu',
    'sentry',
    'serverfault',
    'shazam',
    'shell',
    'shopify',
    'showpad',
    'siemens',
    'signal',
    'simpleicons',
    'sinaweibo',
    'sitepoint',
    'sketch',
    'skillshare',
    'skyliner',
    'skype',
    'slack',
    'slashdot',
    'slickpic',
    'slides',
    'smashingmagazine',
    'smugmug',
    'snapchat',
    'snapcraft',
    'snyk',
    'society6',
    'socket-dot-io',
    'sogou',
    'solus',
    'sonarcloud',
    'sonarlint',
    'sonarqube',
    'sonarsource',
    'songkick',
    'sonicwall',
    'sonos',
    'soundcloud',
    'sourceengine',
    'sourceforge',
    'sourcegraph',
    'spacemacs',
    'spacex',
    'sparkfun',
    'sparkpost',
    'spdx',
    'speakerdeck',
    'spectrum',
    'spotify',
    'spotlight',
    'spreaker',
    'spring',
    'sprint',
    'square',
    'squareenix',
    'squarespace',
    'stackbit',
    'stackexchange',
    'stackoverflow',
    'stackpath',
    'stackshare',
    'stadia',
    'statamic',
    'staticman',
    'statuspage',
    'steam',
    'steamworks',
    'steem',
    'steemit',
    'steinberg',
    'stencyl',
    'stitcher',
    'storify',
    'storybook',
    'strapi',
    'strava',
    'stripe',
    'strongswan',
    'stubhub',
    'styled-components',
    'styleshare',
    'stylus',
    'sublimetext',
    'subversion',
    'superuser',
    'svelte',
    'svg',
    'svgo',
    'swagger',
    'swarm',
    'swift',
    'symantec',
    'symfony',
    'synology',
    't-mobile',
    'tableau',
    'tails',
    'tailwindcss',
    'tapas',
    'teamviewer',
    'ted',
    'teespring',
    'tele5',
    'telegram',
    'tencentqq',
    'tencentweibo',
    'tensorflow',
    'teradata',
    'terraform',
    'tesla',
    'themighty',
    'themoviedatabase',
    'tidal',
    'tiktok',
    'timescale',
    'tinder',
    'todoist',
    'toggl',
    'tomorrowland',
    'topcoder',
    'toptal',
    'tor',
    'toshiba',
    'trainerroad',
    'trakt',
    'travisci',
    'treehouse',
    'trello',
    'trendmicro',
    'tripadvisor',
    'trulia',
    'trustpilot',
    'tumblr',
    'turkishairlines',
    'twilio',
    'twitch',
    'twitter',
    'twoo',
    'typescript',
    'typo3',
    'uber',
    'ubereats',
    'ubisoft',
    'ublockorigin',
    'ubuntu',
    'udacity',
    'udemy',
    'uikit',
    'umbraco',
    'unicode',
    'unity',
    'unrealengine',
    'unsplash',
    'untappd',
    'upwork',
    'v',
    'v8',
    'vagrant',
    'valve',
    'veeam',
    'venmo',
    'verizon',
    'viadeo',
    'viber',
    'vim',
    'vimeo',
    'vine',
    'virb',
    'visa',
    'visualstudio',
    'visualstudiocode',
    'vk',
    'vlcmediaplayer',
    'vodafone',
    'volkswagen',
    'vsco',
    'vue-dot-js',
    'w3c',
    'wattpad',
    'waze',
    'weasyl',
    'webassembly',
    'webauthn',
    'webcomponents-dot-org',
    'webmin',
    'webpack',
    'webstorm',
    'wechat',
    'whatsapp',
    'wheniwork',
    'whitesource',
    'wii',
    'wiiu',
    'wikipedia',
    'windows',
    'wire',
    'wireguard',
    'wish',
    'wix',
    'wolfram',
    'wolframlanguage',
    'wolframmathematica',
    'wordpress',
    'workplace',
    'wpengine',
    'write-dot-as',
    'x-dot-org',
    'x-pack',
    'xamarin',
    'xbox',
    'xcode',
    'xdadevelopers',
    'xero',
    'xfce',
    'xiaomi',
    'xing',
    'xmpp',
    'xrp',
    'xsplit',
    'yahoo',
    'yamahacorporation',
    'yamahamotorcorporation',
    'yammer',
    'yandex',
    'yarn',
    'ycombinator',
    'yelp',
    'youtube',
    'z-wave',
    'zalando',
    'zapier',
    'zdf',
    'zeit',
    'zend',
    'zendesk',
    'zendframework',
    'zeromq',
    'zerply',
    'zhihu',
    'zillow',
    'zingat',
    'zoom',
    'zorin',
    'zulip',
];