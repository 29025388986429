import React from 'react';
import { Layout } from './Layout/Layout';

document.addEventListener('copy', e => {
    const text = window.getSelection().toString().replace(/[\n\r]+/g, '');
    e.clipboardData.setData('text/plain', text);
    e.preventDefault();
});

function App() {
    return <Layout/>
}

export default App;
